import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-what-provisions-are-in-place-for-feeding-children-every-day",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-what-provisions-are-in-place-for-feeding-children-every-day",
        "aria-label": "a what provisions are in place for feeding children every day permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. What Provisions Are In Place For Feeding Children Every Day?`}</h2>
    <p>{`PSI offers a lunch programme provided by a private catering company. Alternatively, students may bring their lunch from home. Primary students eat in the cafeteria during their assigned daily lunch periods, whilst Secondary students can choose between the cafeteria and the We Belong Cafe. The School recognises that some students may have special nutritional needs or individual preferences, and encourages parents to prepare their child’s lunch when this is the case.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d9da7bb0b42a3b51a39214e6bdb528f/c2d9c/img1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEA0lEQVQ4yy3M+W9TBQDA8fcXyC8a7xCYCxsWN1ZnWYGuW9djfb1e+9q+9vW+u55rWTfccMJkbAgkMBddlighHMMB4VgQFwgJMYaERBM0HoToL5IYo9GYEKPRfI2JP3x+/QipaJBGKUUhG0Nyi0guO7JXJOh3oShewiEvakhivJKilpIJuQbw2vtJhRyMxH3UMkHGCirjxRijWRXhs3t3qY8kcdoHad/yEu1b2tB0vUy3toseXQ89O7Rs36ElmwjQzCnkFDtRn5mpWpz9jST7KmEmSyHeqCfZW4oiXLv5CblskmFTH16nCbt1J26HkUDASTQdIVZIEoj6ifjMBEQDbosep1mPKllJRRSq9TEKhTKK30c+qSIsnblKoVJHu7UNv2giqXiI+0WycR+Nep5Gs0g2FyEW9qIEZOzWAfr1Wvp36bCKDpRMEzXfQg7FcEs+hJNnLxGJhAl6hgk7LQSdFrJRhbjPQViy4ZfNiA4TkldCkpzEVRnZIyJa+rEOdOMd7GZohwabUYdO9ypCl6aTzq3tSEEv1UKGkaSDgzNFZo9ViCdFpHgHctKAz2UjM9xB2tVL0N5F0Kkno7pImzehDm1C3rURzeYnETRtz2Dq2cxBZZBDASsrWQMX37RxbY+BZl5DdqIXX6QPo2GQ2bEMRyKDVFQnAbmP915PMJFXSLk7mcn1MtDbjtDvep7jxVe4F9QyJ2lp7TEyXWxnPbWT634dq3ULmcgwlfoY4XiOXCzO4bl5qo0JyokIrdoIpUKGZr3KgUOLCKbIJlbKOzk1YaTwVg/Vpp7Fgp712gBLug4OyxoKuRjhaAJdTzfabR1YrQOEYwHGSpMoDh/2fjNhT5Cwx42QzbSxd/9umpO7kUc62Vvq5fgBMwvHPLwbNnC8LhLJ5TEY9aT/S+ppZNFFPOolkRcJe12kEioT00nq80MI0wc07FOepuzeyInKdpYbOs5MDXFqzszSrImJlAlRimP3bMdqNJIJRSmpKuVCEoulg9FcBCXkxycNk4r6EBzKBoqjLzJT1bCwMMzRRSNv53S8072BXOA5RvbFGJ9d5tmnnkDzQgchMYy2cxuNqST5UT+toyoFr5chrR7TazaEoqzHn+ij2DTRqosciduY1GmYt/Zyohbn9Np17nzzI3OLy6RKTop7fARcbqoFiakFCSnSg2TvJq3aKGXTCFc/usHla2tcurrG6oXLfLh6gZUL5zmzco7zFy9xavUK5y7f4MqtT1laOU1repRao0VpvEh5OkNQjVEeG2X+xGFmZucQ/gT+/t8fwO//wM+P/+LRb4/5/qdfefDoF+4//IGbd7/g7Mefc3LtS87d/pqzt7/l5PpDTt/5jg9uPeD99a9Yvn6ffwFtn5QgK4g5OQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img1",
            "title": "img1",
            "src": "/static/9d9da7bb0b42a3b51a39214e6bdb528f/c1b63/img1.png",
            "srcSet": ["/static/9d9da7bb0b42a3b51a39214e6bdb528f/5a46d/img1.png 300w", "/static/9d9da7bb0b42a3b51a39214e6bdb528f/0a47e/img1.png 600w", "/static/9d9da7bb0b42a3b51a39214e6bdb528f/c1b63/img1.png 1200w", "/static/9d9da7bb0b42a3b51a39214e6bdb528f/c2d9c/img1.png 1326w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Nutritious food options are available every day in the cafeteria.`}</em></p>
    <h4 {...{
      "id": "separate-dishes-at-lunch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#separate-dishes-at-lunch",
        "aria-label": "separate dishes at lunch permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Separate Dishes at Lunch`}</h4>
    <p>{`Secondary students may opt to have separate lunch items as opposed to the full meal. In that case, their smart card is charged only for the purchases they make.`}</p>
    <h4 {...{
      "id": "a-la-carte-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#a-la-carte-options",
        "aria-label": "a la carte options permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A La Carte Options`}</h4>
    <p>{`The We Belong Cafe is open from 8:00 - 16:00 each day. Parents may purchase items whenever they are in the building. Secondary students may purchase food or drink items during their lunch, break times, DP students’ study periods and / or after the school day.`}</p>
    <h4 {...{
      "id": "primary-morning-snacks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#primary-morning-snacks",
        "aria-label": "primary morning snacks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Primary Morning Snacks`}</h4>
    <p>{`Students are expected to bring in a healthy snack from home for their morning break. Snacks should be in reusable containers. Please note that we do not warm up snacks or keep them cool. In the event they have forgotten their snack, they may purchase items from the approved snacks list.`}</p>
    <h4 {...{
      "id": "secondary-snacks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#secondary-snacks",
        "aria-label": "secondary snacks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Secondary Snacks`}</h4>
    <p>{`The cafe is open throughout the day. Secondary students can purchase healthy snacks during break times, before and after school. Grade 11 and 12 students may also use the cafe during any open period.`}</p>
    <h2 {...{
      "id": "b-what-other-services-does-the-school-provide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-what-other-services-does-the-school-provide",
        "aria-label": "b what other services does the school provide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. What Other Services Does The School Provide?`}</h2>
    <h4 {...{
      "id": "medical-office",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#medical-office",
        "aria-label": "medical office permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medical Office`}</h4>
    <p>{`The Medical Office provides a range of services, from minor injuries and dispensing of approved medications to emergency services, as needed. `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1jYoJV0b6ga6Av4B4oyX1786VtEpRhaFzUerILYclmZY/edit?ts=5db1a2cc"
      }}>{` These protocols `}</a>{` explain how to work with the medics on duty to meet your child’s medical needs.`}</p>
    <h4 {...{
      "id": "learning-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#learning-support",
        "aria-label": "learning support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Support`}</h4>
    <p>{`The learning support programme is designed to help students develop appropriate levels of independence, responsibility and skills in their academic, emotional and social lives at the school, so that they may have full and equal access to all parts of the PSI curricular and extracurricular programmes. PSI is committed to using a range of strategies to support students, with the understanding that all students are served in their mainstream classes, with additional support provided by the Learning Support teacher working together with classroom teachers. The School is committed to the Principles of Good Practice outlined by the IB.`}</p>
    <p>{`The programme is designed to serve a small number of students in need of limited academic assistance or with mild to moderate learning needs, which we define as no more than two grade levels below expected performance in any subject area. In addition, students must demonstrate an appropriate level of independence and must meet the school’s social / behavioural expectations.`}</p>
    <p>{`Typically, classroom teachers identify students who are struggling and work with the School’s learning support teachers, school leadership, and the student’s other teachers to identify the child's needs, develop strategies to support the child, and determine if further assessment is needed. In the Secondary School, specific after-school programmes like Homework Club and Transition Study Skills are available to all students who need additional support in getting and staying organised. Students may also utilise their Flex Time during the day to connect with teachers to get extra support that might be needed.`}</p>
    <p>{`Parents are always brought into discussions about support strategies so that the School can take a team approach with each child. Parents are also encouraged to contact the child’s homeroom or subject area teacher to discuss any on-going issues or concerns.`}</p>
    <p>{`The School reserves the right to exit students whose needs cannot be met once admitted and has a set of protocols in place for that process.`}</p>
    <h4 {...{
      "id": "support-for-highly-able-learners",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#support-for-highly-able-learners",
        "aria-label": "support for highly able learners permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support for Highly Able Learners`}</h4>
    <p>{`In addition to many systems of support for English language learners, students with special needs, and children in need of remediation in any subject, PSI also aims to provide students with appropriate levels of academic, artistic, and / or athletic challenge. The School uses a range of strategies to support students, with the understanding that all students are served in their mainstream classes, with additional support provided by the extracurricular programmes, off campus options, on-line courses, or other strategies. PSI does not offer specific courses or programmes for students who are designated as highly able learners. Nevertheless, students with exceptional talents or abilities are provided with appropriate teacher support and resources through the School’s differentiated learning programmes.`}</p>
    <h4 {...{
      "id": "libraries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#libraries",
        "aria-label": "libraries permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Libraries`}</h4>
    <p>{`Both the Primary and Secondary Schools have dedicated libraries with a combination of print materials and online resources. Students in EC - Grade 8 have a scheduled library time, but all students can use the library. Parents are welcome to check out books for their children and themselves.`}</p>
    <h5 {...{
      "id": "library-hours",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#library-hours",
        "aria-label": "library hours permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Library Hours`}</h5>
    <p>{`Mon, Tues, Thurs, Fri 08:00 - 15:30`}</p>
    <p>{`Wednesday 08:00 - 15:00`}</p>
    <h5 {...{
      "id": "loaning-books",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#loaning-books",
        "aria-label": "loaning books permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loaning Books`}</h5>
    <p>{`Early Childhood 3: 2 books at a time`}</p>
    <p>{`Early Childhood 4: 2 books at a time`}</p>
    <p>{`Kindergarten - Grade 5: 5 books or 4 books and 1 DVD at a time`}</p>
    <p>{`Grades 6 - 12 have access to the library collection of materials. Loan limitations are as follows`}{`*`}{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Grade 6 - Grade 10 MYP students: up to 20 items at a time including 10 library books and / or periodicals for 14 days, 10 textbooks with a fixed due date, and 1 DVD for 7 days`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Grade 11 and Grade 12 DP students: standard borrowing loan limitations apply: up to 20 items at a time including 10 library books and / or periodicals for 14 days, 10 textbooks with a fixed due date, and 1 DVD for 7 days, with an opportunity to keep and renew required materials for a longer period of time and borrow all required library materials over the summer period and school holidays.`}</p>
      </li>
    </ul>
    <p>{`*`}{`Loan limitations are extended for use with the Personal Project and the Extended Essay.`}</p>
    <p>{`If a student is involved in a particular unit, the loan period and number of books on loan can be increased at the librarian’s discretion. Students may check out new books whenever they have returned books currently on loan.`}</p>
    <p>{`The normal loan period is two weeks; this can be extended twice, giving a total of 6 weeks maximum loan before the book(s) must be returned to the library. The library reserves the right to recall any book or item if it is needed within the School for a particular unit of study.`}</p>
    <p>{`Parents can request a library account in their own name and may borrow up to 10 library materials at a time. For more information please contact one of our teacher librarians via email `}<a parentName="p" {...{
        "href": "mailto:library@psi.kiev.ua"
      }}>{` library@psi.kiev.ua `}</a></p>
    <h5 {...{
      "id": "summer-borrowing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#summer-borrowing",
        "aria-label": "summer borrowing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Summer Borrowing`}</h5>
    <p>{`Returning students and parents who have returned all outstanding materials may check out books for the summer holiday period. Summer borrowing limits are as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`EC - Grade 10 students: up to 10 books`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`G11 students: All resources required for their summer studies.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Parents: up to 10 books`}</p>
      </li>
    </ul>
    <p>{`More detailed information regarding library services can be found in the Library Policies and Procedures Handbook upon request from the librarian.`}</p>
    <h4 {...{
      "id": "lost-and-found",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#lost-and-found",
        "aria-label": "lost and found permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lost and Found`}</h4>
    <p>{`There is a lost and found area in Building B in the main reception area as well as in Building A near the Gymnasium. Students who lose any item during the school day should check the lost and found area. Primary parents are asked to check the lost and found area when on campus.`}</p>
    <p>{`In Secondary School, students who cannot find lost items should contact the Secondary PA, who will place an ‘advert’ in the daily bulletin and / or will ask the principal to initiate a review of the security camera footage, where possible.`}</p>
    <p>{`Two weeks before each holiday (in October, December, February, April / May and June), the School sends a reminder to parents and students to pick up lost items. Any unclaimed items are donated to local charities during vacation periods.`}</p>
    <p>{`Parents should clearly label lunch boxes, books, bags and clothes to make lost items easier to return to the appropriate owner. Students should not bring items of value to school. This includes large sums of money. We strongly encourage Secondary students to lock up any valuables in their lockers.`}</p>
    <p><strong parentName="p">{`The School cannot be held responsible for lost or stolen items.`}</strong></p>
    <h4 {...{
      "id": "school-bus-transport",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#school-bus-transport",
        "aria-label": "school bus transport permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`School Bus Transport`}</h4>
    <p>{`Transport by bus between the School and home is available from an external provider to students from EC3 to Grade 12 at an additional charge. Parents who decide to use the school’s transport service after they have paid for tuition should contact our bus programme coordinator (`}<a parentName="p" {...{
        "href": "mailto:bus@psi.kiev.ua"
      }}>{` bus@psi.kiev.ua `}</a>{`).`}</p>
    <p>{`Each bus seats a maximum of 18 children. All buses are equipped with seat belts; all passengers must wear them at all times. The School has an adult monitor on each bus who speaks English and Ukrainian. Although the monitor may be different on afternoon bus runs, all monitors have a list of parent names and phone numbers. At the start of the school year and with each new ECA season, the bus programme coordinator informs parents of the name and mobile phone number of their child’s bus monitor and the time that the child will be collected from home each morning. In the evenings, Primary school children must be met at the bus stop by a known adult.`}</p>
    <p>{`As bus passengers, children at all grade levels must:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Only get on or off the bus when it is fully stopped and they have been told to do so by the bus monitor`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wear seat belts at all times`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Follow the directions of the bus monitor and ask for help if needed`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Report any concerns immediately to a bus monitor`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Stay seated and quiet during travel time`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accept responsibility for being on time`}</p>
      </li>
    </ul>
    <p>{`The bus schedule is organised so that the maximum journey time is 45 minutes, but traffic, road conditions, and weather can impact on this time. If the bus is likely to be late, the bus monitor calls parents in advance with a revised time. If the schedule is changed significantly in any way, the School calls or sends an SMS to parents. If there are any concerns about the bus programme, please contact the bus coordinator at  `}<a parentName="p" {...{
        "href": "mailto:bus@psi.kiev.ua"
      }}>{`bus@psi.kiev.ua`}</a>{` .`}</p>
    <p>{`It is very important that students arrive on time for their morning pick-up. Late students delay all other pick-up times on the route and make the bus late to school. For these reasons, the following procedures are in place:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`If the student is not at the pick-up spot at their designated time, the bus monitor calls the parent and warns them that the bus will leave in 2 minutes time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If the bus leaves without the student, the bus monitor calls the parents to explain that the bus has left and the student must find another way to school.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If the bus is early to the pick-up time, the bus still waits until 2 minutes after the designated pick up time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The bus monitor lets the Transport Coordinator know that the student was late.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After 2 lates, the building principal writes an email to the parents.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After 3 lates, the student is suspended from the bus for 3 days.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Frequent and continual lateness could end in suspension of all bus privileges for a longer period of time`}</p>
      </li>
    </ol>
    <p>{`For more information on our transportation system, please see the `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1dAVpN_cUFoNudYmjzXZg7cS8ibVaZJR_03W8LXGiHtQ/edit"
      }}>{` PSI Transportation Protocols for Parents. `}</a></p>
    <h4 {...{
      "id": "technology",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#technology",
        "aria-label": "technology permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technology`}</h4>
    <ul>
      <li parentName="ul">{`Computer and Internet Use *`}</li>
    </ul>
    <p>{`Both libraries have up-to-date computers, which are supervised by adult staff members. There are grade level sets of iPads or Chromebooks for EC - Grade 5. The Secondary School has a Bring-Your-Own-Device programme that asks students to bring their own laptops to school.`}</p>
    <p>{`All computers and school supplied mobile devices are networked and have access to a dedicated wireless internet service. These tech provisions are meant to support the curriculum and provide students and staff with the modern technology necessary for research and communication in today’s world. In addition, we also encourage Secondary students to bring headphones to School, as these are often used for language activities and multimedia use.`}</p>
    <p>{`All students and staff are expected to follow the `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1MWTqF6KnR1Ps9jc_S4jiUqCaogYiy7VHV5qChiYRUYY/edit"
      }}>{` PSI Responsible Use Agreement (RUA) `}</a>{` which is distributed and discussed with students at the start of the school year. Students should follow the guidelines established by each teacher, including turning the devices off upon request.`}</p>
    <p>{`The students may not be on gaming sites during instructional or ECA time. They are not monitored for lunch time, breaks or on the bus.`}</p>
    <p>{`Please note that the school is not responsible for lost or stolen items, so we encourage students to keep electronic devices with them or lock them up.`}</p>
    <h5 {...{
      "id": "phone-and-mobile-use",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#phone-and-mobile-use",
        "aria-label": "phone and mobile use permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Phone and Mobile Use`}</h5>
    <p><em parentName="p">{`Primary`}</em></p>
    <p>{`Parents should consider carefully if a phone is needed at school for students in the Primary School. PSI recommends that it is not needed but does understand that phones can be considered a 'safety item' while travelling in the city before and after school.`}</p>
    <p>{`It is school policy that Primary School students may not use their cell phones from 8:15 - 15:20 unless for educational purposes approved by the teacher. The Reception phone is always available for a child who needs to call home for an important issue. If a family wishes to send a phone with their child, it must stay in his / her school bag during the school day. Any phones used during the school hours listed above, unless approved for educational purposes, are confiscated until the end of the school day. Any repeat offences result in parents either needing to come to school to collect the phone or phones not being allowed at all.`}</p>
    <p><em parentName="p">{`Secondary`}</em></p>
    <p>{`Mobile phones may be used as educational tools (e.g., for access to the Internet, as calculators, etc.) at the teacher’s discretion. They may not be used in class for non-educational purposes and must be turned off and put away upon teacher request. Teachers may take the phones of any students who are using them inappropriately. Repeated offences may result in the phone being turned over to the school leadership, who may choose to hold them during the school day for a specified period of time.`}</p>
    <h2 {...{
      "id": "c-what-supports-are-in-place-if-my-child-is-not-appropriately-placed-in-a-grade-level",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#c-what-supports-are-in-place-if-my-child-is-not-appropriately-placed-in-a-grade-level",
        "aria-label": "c what supports are in place if my child is not appropriately placed in a grade level permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`C. What Supports Are In Place If My Child Is Not Appropriately Placed In A Grade Level?`}</h2>
    <h4 {...{
      "id": "support-systems",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#support-systems",
        "aria-label": "support systems permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support Systems`}</h4>
    <p>{`In addition to our Learning Support and English as an Additional Language (EAL) programmes, both Primary and Secondary Schools have a range of support systems in place to assist students. These include but are not limited to the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Academic / behavioural contracts`}</strong>{` establish a set of goals for the student and outline the responsibilities of the student, the parents and the School`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`After school supports:`}</strong>{` Support in the Secondary School ranges from dedicated time with the teacher or TA to special sessions to teach specific organization and study skills. Secondary School also offers a Homework Club to give students time to work on their homework whilst still in school.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`The Secondary Tutoring Centre`}</strong>{` offers lunchtime sessions for students in Grades 6-12. Staffed by Grade 11 and 12 students, the centre offers opportunities for students to get help on homework assignments or on specific content area skills or knowledge.`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "retention",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#retention",
        "aria-label": "retention permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Retention`}</h4>
    <p>{`It’s important to note that it is rare for a child to be asked to repeat a grade level at PSI. It most often occurs when students have had significant gaps in their learning due to previous educational experiences, multiple and frequent absences and / or learning issues that are interfering with his / her forward progress. In Grades 9 - 12, students may also be retained if they are short of credits for graduation.`}</p>
    <p>{`Every student who is a possible candidate for retention is first referred to the Learning Support Team (LST) by the classroom teacher. A file is started on the child and regular meetings are held to discuss the child’s progress. Parents are informed of the specific issues and concerns. A team of individuals work to bring the student up to grade level. This always includes the classroom teacher(s) and the Learning Support teacher, and may also include the appropriate IB coordinator, counsellor and / or outside supports. Every effort is made to identify the underlying issues and to address them.`}</p>
    <p>{`At the end of the first semester (mid-January), the team reviews the student’s progress. If there is an ongoing concern that the student may not reach grade level targets, the team and the principal meet with the parents to discuss next steps. If there is a possibility that the student will be retained in their current grade level, that is documented and discussed.`}</p>
    <p>{`By the end of April, the team meets with parents again to discuss the child’s progress. If the School believes that the child will not hit the learning targets by the end of the year, they discuss the possibility of retention with the parents.`}</p>
    <p>{`In June, the principal makes a final decision about retention based on input from the Learning Support Team. This may be reviewed again in August, but parents should understand that it is unlikely that students will make sufficient progress over the summer to warrant a reversal of the decision. Whilst parent input is an essential part of this process, the final decision belongs to the School.`}</p>
    <p>{`Students who are retained automatically go on the list for regular review from the Learning Support Team and the counsellor to ensure that the child’s educational and emotional needs are being met.`}</p>
    <h4 {...{
      "id": "acceleration-ec---grade-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#acceleration-ec---grade-8",
        "aria-label": "acceleration ec   grade 8 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Acceleration (EC - Grade 8)`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Decisions about acceleration are generally made between mid-September and mid-January.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The classroom teacher liaises with the principal and the Learning Support Team (LST) to discuss the student’s profile. It is important to note that decisions about acceleration are not only academic. The School also takes into consideration social and physical maturity, as these are an important part of ensuring a student’s success in any grade level.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The teacher or a member of the LST team administers any available end-of-year assessments, presents the Reading Assessment results (which must be strongly exceeding expectations; higher than all students in the grade and not the lowest in the following grade), a writing sample, MAP results and any other pertinent work samples.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A teacher from the following year is consulted to compare a writing sample and any pertinent work samples to ensure this student will easily fit into the grade.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once students are moved up a grade, they are monitored for at least one semester to ensure that the move is successful.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The principal makes the final decision on all matters regarding acceleration of grades.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "acceleration-grades-9---12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#acceleration-grades-9---12",
        "aria-label": "acceleration grades 9   12 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Acceleration (Grades 9 - 12)`}</h4>
    <p>{`Students in high school are rarely accelerated, as it negatively impacts their credits toward graduation. However, the school does work with students and their families to look for appropriate enrichment opportunities, including online courses that could potentially lead to college credit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      